import { useState } from "react";
import CardData from "../../components/CardData";
import Menu from "../menu/Menu";
import MessageHeader from "./MessageHeader";
import './styles/styles.css';
import CalendarApp from "../callendar/Calendar";
import Footer from "../footer/Footer";

const Messages = () => {
    const [ title , setTitle ] = useState('contacts');
    const [ data , setData ] = useState([{id:'1',subject:'text 1',text:'texto 1 a enviar'},{id:'2',subject:'text 2',text:'texto 2 a enviar'},{id:'3',subject:'text 3',text:'texto 3 a enviar'},{id:'4',subject:'text 4',text:'texto 4 a enviar'}]);
    const [ dataStructure , setDataStructure ] = useState({id:'1',name:'Jose',number:'23442343'});
    const [ flag , setFlag ] = useState(false);
    return (
        <div className="row vh-100">
            <div className="col vh-100">
                <div className="row message-header">
                    <div className="col h-100"><MessageHeader /></div>
                </div>
                <div className="row vh-100 bg bg-dark">
                    <div className="col-3 message-menu bg bg-dark">
                        <Menu setTitle={setTitle} setData={setData} setDataStructure={setDataStructure} flag ={flag} setFlag={setFlag}/>
                    </div>
                    <div className="col-9 message-message bg bg-dark border border-secondary">{title=='callendar'?(
                        <CalendarApp title={title} data={data} dataStructure={dataStructure}  />
                            ):(
                        <CardData title={title} data={data} dataStructure={dataStructure} />
                    )}
                    </div>
                </div>
                <div className="row message-footer">
                    <Footer />
                </div>
            </div>
            
        </div>
    )
}
export default Messages;