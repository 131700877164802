const Footer = () => {
    return (
        <div className="row bg bg-dark">
            <div className="col d-flex justify-content-center align-items-center bg bg-dark text-center text-white">
                <p className="text-center text-white">Smart Solutions</p>
            </div>            
        </div>
    );
}
export default Footer;
