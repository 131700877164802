const TBody = (props) => {
    return(
        <>
            <tbody>
                {props.data.map((data, index) => (
                    <tr key={index}>
                        {Object.values(data).map((element, index) => (
                            (index!==0 && <td key={index} >{element}</td>)
                            ))}
                    <td><button onClick={() => props.handleDeleteElement(data.id) } className="btn btn-danger"></button></td>
               </tr>
                ))}
            </tbody>
        </>
    )
}
export default TBody;