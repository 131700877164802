
import React from 'react';
import LoginLogo from "../login/LoginLogo";
import "./styles/styles.css";
import { NavLink } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
const Header = () => {
    const navigate = useNavigate();
    const handleNavLink = () => navigate("/login");
    return (
        <div className="row h-100">
            <div className="col-3 d-flex justify-content-center align-items-center bg bg-dark"><LoginLogo className='login-logo bg bg-dark' /></div>
            <div className="col-9 d-flex justify-content-end align-items-center bg bg-dark"><a onClick={handleNavLink} className='logout-a btn btn-dark text-white btn-large btn-outline'>Log Out</a><div className='line link-opacity-100'></div></div>
        </div>
    )
}
export default Header;