import { useEffect, useState } from "react";
import { connectionData, endpoint } from "../../connection/connection";
import MenuItem from "./MenuItem";
import "./styles/styles.css";
const Menu = (props) => {
    const [ contacts , setContacts ] = useState([]);
    const [ messages , setMessages ] = useState([]);
    const [ users , setUsers ] = useState([]);
    const [ sends , setSends ] = useState([]);

    const itemSelect = async (itemValue) => {
        console.log(itemValue);
        switch (itemValue) {
            case 'contacts':
                props.setTitle('contacts');
                props.setDataStructure({id:'1',name:'Jose',number:'23442343'});
                break;
            case 'messages':
                props.setTitle('messages');
                props.setDataStructure({id:'1',subject:'text 1',text:'texto 1 a enviar'});
                break;
            case 'sends':
                props.setTitle('listas');
                props.setDataStructure({id:'1',date:'Jose',numbers:'23442343',text:'agencia'});
                break;
            case 'users':
                props.setTitle('users');
                props.setDataStructure({id:'1',user:'user1',password:'13123123'});
                break;
            case 'callendar':
                props.setTitle('callendar');
                props.setData(await connectionData(endpoint,'contacts','GET'));
                props.setDataStructure({id:'1',name:'Jose',number:'23442343'});
                break;
            default:
                break;
        }
        
    }
    return (
        <div className="h-50 bg bg-dark"> 
            <div className="row item"><MenuItem onClick={ () => itemSelect('contacts') } nameItem='Contacts' icon='fa-solid fa-phone' /></div>
            <div className="row item"><MenuItem onClick={ () => itemSelect('messages') } nameItem='Messages' icon='fa-regular fa-envelope' /></div>
            <div className="row item"><MenuItem onClick={ () => itemSelect('sends') } nameItem='Sends' icon='fa-solid fa-envelope-circle-check'/></div>
            <div className="row item"><MenuItem onClick={ () => itemSelect('callendar') } nameItem='Callendar' icon='fa-solid fa-calendar-days' /></div>
            <div className="row item"><MenuItem onClick={ () => itemSelect('users') } nameItem='Users' icon='fa-regular fa-address-book' /></div>
        </div>
    )
};
export default Menu;