import { useEffect, useState } from "react";
import LoginLogo from "./LoginLogo";
import { connectionData, endpoint } from "../../connection/connection";
import { useNavigate } from "react-router-dom";
import LoginForm from "./LoginForm";
import LoginFormRegister from "./LoginFormRegister";

const LoginCard = () => {
    const [ flagLogInRegister , setFlagLoginRegister ] = useState(false);
    return (
        <div className="card login-card mt-5">
            <div className="card-header"><h6 className="text-white text-center">{!flagLogInRegister?"Welcome":"Make your user"}</h6></div>
            <div className="card-body text-center">
                <LoginLogo />
                { !flagLogInRegister? 
                    <LoginForm />
                        :
                    <LoginFormRegister setFlagLoginRegister={setFlagLoginRegister} flagLogInRegister={flagLogInRegister} />
                }
                
            </div>
            <div className="card-footer"><p onClick={() => setFlagLoginRegister(!flagLogInRegister)} className="text-white text-center">{!flagLogInRegister?"Create acount":"Log In"}</p></div>
        </div>
    )
}
export default LoginCard;