import React, { useEffect, useState } from "react";
import { connectionData, endpoint } from "../connection/connection";
import TBody from "./TBody";
import THead from "./THead";
import InputFilter from "./InputFilter";
const Table = (props) => {
    const [ dataTable , setDataTable ] = useState([]);
    const [ flagDelete , setFlagDelete ] = useState(false);
    const [ inputFilterData , setInputFilterData ] = useState([]);
    useEffect(() => {
        const dataFetch = async () => {
            console.log(props);
            if (props.title) {
                const newDataTable = await connectionData(endpoint, props.title, 'GET');
                setDataTable(newDataTable);
                setFlagDelete(false);
            }    
        }
            dataFetch();
            console.log(flagDelete)
            console.log("UseEffect");
    }, [props, flagDelete ]);
    useEffect(() => {
        const handleFilter = () => {
            const filteredData = dataTable.filter((data) => (
                Object.values(data).some((value) =>
                    String(value).toLowerCase().includes(inputFilterData)
                )
            ));
            setDataTable(filteredData);
        }
        handleFilter();
    },[inputFilterData])
    const handleDeleteElement = async (id) => {
        console.log(props)
        const response = await connectionData(endpoint, props.title + "/" + id, "DELETE");
        response.affectedRows && setFlagDelete(true);
    }
    return (
        <>
            <InputFilter setInputFilterData={setInputFilterData} />
            <div className="table-responsive">
                <table className="table table-dark table-striped" >
                    <THead structure={props.structure} />
                    <TBody data={dataTable} handleDeleteElement={handleDeleteElement} />
                </table>
            </div>
        </>
    );
};

export default Table;
